footer {
    display: flex;
    justify-content: center;
    background-color: #C4D2F3;
    padding: 0.1em;
    box-shadow: inset 0px 4px 3px rgba(15, 50, 32, 0.167);
}

.fa-phone {
    margin: 0 1em
}

footer p img:first-of-type {
    margin-left: 0.7em;
}

footer p a,
footer p {
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: black;
    font-size: 25px;
    font-weight: bold;

}

footer p a:hover {
    text-decoration: underline;
    text-underline-offset: 0.2em;
    color: black;

}

p img {
    text-align: center;
    align-content: center;
    display: flex;
    justify-content: center;
    margin-left: 0.2em;
}

#phone {
    margin-left: 0.3em;
    color: darkred;
}

@media (max-width:450px) {

    footer p a{
        display: flex;
        justify-content: center;
        text-decoration: none;
        color: darkred;
        font-size: 1em;
        font-weight: bold;
    }
    footer p {
        display: flex;
        justify-content: center;
        text-decoration: none;
        color: black;
        font-size: 1.2em;
        font-weight: bold;
    }

    footer img {
        width: 18px;
        height: 18px;
    }
}