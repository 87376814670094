.odesa-photos {
    margin: 2em 2em;
    margin-bottom: 0em;
}

.odesa-img {
    display: block;
    width: 100%;
    border-top-right-radius: 10em;
    border-bottom-left-radius: 10em;
}

.odesa-photos h2 {
    text-align: center;
    font-size: 35px;
    text-decoration: underline;
    text-decoration-color: black;
    text-underline-offset: 0.2em;
}

.odesa-map {
    display: flex;
    width: 90%;
    height: 20em;
    justify-content: center;
    align-items: center;
    margin: 1em auto;
}

.prices-odesa {
    display: flex;
    justify-content: space-around;
    text-align: center;
}

.price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 30px;
}

section div {
    display: flex;
    flex-direction: row;
}

section section {
    display: flex;
    font-size: 25px;
}

.price p {
    display: flex;
    margin: 0.2em 0em;
    text-decoration: underline;
    text-underline-offset: 0.2em;
}

.price h5 {
    display: flex;
    margin: 0.2em 0.5em;
    font-size: 35px;
}

.comfort {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.comfort p {
    display: flex;
    margin: 0.2em 1.5em;
}

.comfort-item p i {
    margin-left: 0.2em;
}

@media (max-width:450px) {
    .prices-odesa {
        display: flex;
        flex-direction: column;
    }

    .comfort-item {
        font-size: 20px;
        margin-top: 1.5em;
    }
}
