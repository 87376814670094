.calendar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-bottom: 0em;
    margin-top: 1em;
}

ul {
    list-style-type: none;
}

.month {
    margin: 0em;
    margin-bottom: 0.5em;
    padding: 0em;
    display: flex;
    flex-direction: row;
    text-align: center;
}

.month li {
    padding: 0 1em;
    margin-bottom: 0em;
}

.days {
    margin: 0em;
    padding: 0em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.days li {
    text-align: center;
    justify-content: center;
    width: 2em;
}

.days section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.active {
    background-color: red;
}

.agenda {
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: red;
    padding: 0.2em;
    border-radius: 1em;
    margin-bottom: 1em;
}

.prev {
    cursor: pointer;
}

.next {
    cursor: pointer;
}