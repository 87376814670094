.link {
    text-decoration: none;
}

.apartments {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 0em;
    margin-top: 1em;
}

.odesa,
.botev {
    display: flex;
    gap: 4em;
}

.odesa p,
.botev p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    margin: 0em 1em;
}


.odesa img {
    display: inline-block;
    width: 70em;
    height: 400px;
    margin: 0em 1em;
    border-end-end-radius: 20em;
}

.botev img {
    display: inline-block;
    width: 80em;
    height: 400px;
    margin: 1em;  
    border-start-start-radius: 20em;
}

.apartments h4 {
    text-align: center;
    font-size: 50px;
    margin: 0em;
    color: black;
}

.home-section h3 {
    text-align: center;
    color: black;
}

@media (max-width:450px) {
    .apartments h4 {
        margin-bottom: 0.5em;
    }

    .odesa {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .odesa img {
        display: flex;
        max-width: 80%;
        border-radius: 0em;
        border-radius: 4em;
        margin-bottom: 0em;
        margin: auto;
    }

    .odesa p {
        font-size: 25px;
        margin-top: -2.2em;
        margin-bottom: 1em;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .botev {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
    }

    .botev img {
        display: flex;
        max-width: 80%;
        border-radius: 0em;
        border-radius: 4em;
        margin-bottom: 0em;
        margin: auto;
    }

    .botev p {
        font-size: 25px;
        margin-top: -2.2em;
        margin-bottom: 1em;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
}