@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap');

body {
    margin: 0;
    padding: 0;
    background-color: #C4D2F3;
    font-family: 'Amatic SC', cursive;
}

header {
    display: flex inline-block;
    flex-direction: row;
    background-color: #C4D2F3;
    font-family: 'Amatic SC', cursive;
}

.top-header {
    display: flex;
    justify-content: space-between;
    margin: 0 1em;
    background-color: #C4D2F3;
}

.top-header p {
    display: flex;
    align-items: center;
    margin: 0 0;
}

.top-header p a {
    margin: 0 0.5em;
    color: black;
    text-decoration: none;
    font-size: 25px;
}

.top-header p a:hover {
    color: black;
    text-decoration: underline;
    text-underline-offset: 0.2em;
}

.top-header ul {
    display: flex;
    justify-content: flex-end;
}

.top-header ul li {
    margin: 0 1em;
    text-decoration: none;
    list-style-type: none;
}

.top-header ul li a {
    text-decoration: none;
    color: black;
    font-size: 25px;
}

.top-header ul li a:hover {
    text-decoration: underline;
    text-underline-offset: 0.2em;
    color: black;
}

.bottom-header {
    display: flex;
    align-items: center;
    justify-content: top;
    flex-direction: column;
    background-image: url(/src/img/sozopol-old-town.jpg);
    background-position: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    min-height: 350px;
    border-end-end-radius: 15em;
    border-start-start-radius: 15em;
}

.bottom-header h1 {
    font-size: 40px;
    letter-spacing: 0.2em;
    margin-top: 0.5em;
    margin-bottom: 0.2em;
    color: transparent;
    -webkit-text-stroke: 1px black;
    font-family: 'Amatic SC', cursive;
}

.bottom-header h3 {
    margin: 1em;
    font-size: 20px;
    color: transparent;
    letter-spacing: 0.3em;
    -webkit-text-stroke: 1px black;
    font-family: 'Amatic SC', cursive;
}


@media (max-width:450px) {
    header {
        margin-bottom: -3em;
        max-height: 320px;
    }

    .nav {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .nav li{
        font-size: 0.3em;
    }

    .top-header {
        display: flex;
        flex-direction: column-reverse;
    }

    .top-header p {
        text-align: center;
        justify-content: end;
    }

    .bottom-header {
        background-position: top;
        background-size: 90% auto;
        background-repeat: no-repeat;
        background-image: url(/src/img/sozopol-old-town.jpg);
        border-radius: 8em;
        margin-top: 0;
    }

    .bottom-header h1,
    .bottom-header h3 {
        font-size: 1em;
        margin-top: 2em;
        justify-content: center;
        -webkit-text-stroke: 0.8px black;

    }
}